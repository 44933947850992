<template>
  <form method="post" @submit.prevent="addPartner">
    <div v-if="!isHidden" class="col-12">
      <div class="form-group pt-2">
        <label for="inputEmail">Company Name</label>
        <input
          v-model="partner.name"
          class="form-control"
          id="inputName"
          type="text"
          placeholder="Enter Company Name"
          required
        />
      </div>
      <div class="form-group pt-2">
        <label for="inputEmail">Company Email Address</label>
        <input
          v-model="partner.email"
          class="form-control"
          id="inputEmail"
          type="email"
          placeholder="Enter Company Email"
          required
        />
        <small class="text-danger">{{ errors.first("email") }}</small>
      </div>
      <div class="form-group pt-2">
        <label for="inputPhoneNumber">Company Phone Number</label>
        <vue-tel-input
          v-model="partner.phone_number"
          placeholder="Company Phone Number"
          v-bind="bindProps"
          required
        ></vue-tel-input>
      </div>
      <div class="form-group pt-2">
        <label for="selectCountry">Country</label>
        <select
          v-model="partner.country"
          class="form-control"
          id="country"
          name="country"
          required
        >
          <option value="">Select Country</option>
          <option value="Algeria">Algeria</option>
          <option value="Angola">Angola</option>
          <option value="Benin">Benin</option>
          <option value="Botswana">Botswana</option>
          <option value="Burkina Faso">Burkina Faso</option>
          <option value="Burundi">Burundi</option>
          <option value="Cabo Verde">Cabo Verde</option>
          <option value="Cameroon">Cameroon</option>
          <option value="Central African Republic"
            >Central African Republic (CAR)</option
          >
          <option value="Chad">Chad</option>
          <option value="Comoros">Comoros</option>
          <option value="Congo">Congo</option>
          <option value="Congo">Congo</option>
          <option value="Cote d'Ivoire">Cote d'Ivoire</option>
          <option value="Djibouti">Djibouti</option>
          <option value="Egypt">Egypt</option>
          <option value="Equatorial Guinea">Equatorial Guinea</option>
          <option value="Eritrea">Eritrea</option>
          <option value="Eswatini">Eswatini (formerly Swaziland)</option>
          <option value="Ethiopia">Ethiopia</option>
          <option value="Gabon">Gabon</option>
          <option value="Gambia">Gambia</option>
          <option value="Ghana">Ghana</option>
          <option value="Guinea">Guinea</option>
          <option value="Guinea-Bissau">Guinea-Bissau</option>
          <option value="Kenya">Kenya</option>
          <option value="Lesotho">Lesotho</option>
          <option value="Liberia">Liberia</option>
          <option value="Libya">Libya</option>
          <option value="Madagascar">Madagascar</option>
          <option value="Malawi">Malawi</option>
          <option value="Mali">Mali</option>
          <option value="Mauritania">Mauritania</option>
          <option value="Mauritius">Mauritius</option>
          <option value="Morocco">Morocco</option>
          <option value="Mozambique">Mozambique</option>
          <option value="Namibia">Namibia</option>
          <option value="Niger">Niger</option>
          <option value="Nigeria">Nigeria</option>
          <option value="Rwanda">Rwanda</option>
          <option value="Sao Tome and Principe">Sao Tome and Principe</option>
          <option value="Senegal">Senegal</option>
          <option value="Seychelles">Seychelles</option>
          <option value="Sierra Leone">Sierra Leone</option>
          <option value="Somalia">Somalia</option>
          <option value="South Africa">South Africa</option>
          <option value="South Sudan">South Sudan</option>
          <option value="Sudan">Sudan</option>
          <option value="Tanzania">Tanzania</option>
          <option value="Togo">Togo</option>
          <option value="Tunisia">Tunisia</option>
          <option value="Uganda">Uganda</option>
          <option value="Zambia">Zambia</option>
          <option value="Zimbabwe">Zimbabwe</option>
        </select>
      </div>
      <div class="d-flex justify-content-center pt-3">
        <button class="btn btn-space btn-primary" @click="isHidden = !isHidden">
          Next
        </button>
      </div>
    </div>
    <div v-if="isHidden" class="col-12">
      <div class="form-group pt-2">
        <label for="inputEmail">Company Admin First Name</label>
        <input
          v-model="partner.admin_first_name"
          class="form-control"
          id="inputAdminFirstName"
          type="text"
          placeholder="Enter Company Admin First Name"
          required
        />
      </div>
      <div class="form-group pt-2">
        <label for="inputEmail">Company Admin Last Name</label>
        <input
          v-model="partner.admin_last_name"
          class="form-control"
          id="inputAdminLastName"
          type="text"
          placeholder="Enter Company Admin Last Name"
          required
        />
      </div>
      <div class="form-group pt-2">
        <label for="inputEmail">Company Admin Email Address</label>
        <input
          v-model="partner.admin_email"
          class="form-control"
          id="inputAdminEmail"
          type="email"
          placeholder="Enter Company Admin Email"
          required
        />
      </div>
      <div class="d-flex justify-content-center pt-3">
        <button
          class="btn btn-space btn-primary"
          :disabled="submitting"
          @click="isHidden = !isHidden"
        >
          Back
        </button>
        <button
          class="btn btn-space btn-primary"
          :disabled="submitting"
          type="submit"
        >
          {{ submit }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";

export default {
  name: "PlanPartnerCreationForm",
  data() {
    return {
      submitting: false,
      submit: "Add Partner",
      partner: {
        name: "",
        email: "",
        country: "",
        plan: "",
      },
      error: "",
      isHidden: false,
      bindProps: {
        mode: "international",
      },
    };
  },
  methods: {
    addPartner() {
      this.submitting = true;
      this.submit = "Adding Partner";

      const data = new FormData();
      data.append("name", this.partner.name),
        data.append("email", this.partner.email),
        data.append("phone_number", this.partner.phone_number),
        data.append("country", this.partner.country),
        data.append("admin_first_name", this.partner.admin_first_name),
        data.append("admin_last_name", this.partner.admin_last_name),
        data.append("admin_email", this.partner.admin_email),
        data.append("plan", this.$route.params.id),
        axios
          .post(
            `v1/subscriptions/partners/?access_token=${localStorage.getItem(
              "access_token"
            )}`,
            data,
            { headers: { "content-type": "multipart/form-data" } }
          )
          .then((response) => {
            if (response.status === 201) {
              this.$swal("Partner Added Successfully", "success");
              window.location.reload(true);
            }
          })
          .catch((error) => {
            if (error.response) {
              this.$swal(`${error.response.data}`, "error");
            }
          });
    },
  },
};
</script>

<style></style>
