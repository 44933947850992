<template>
  <div>
    <div v-if="isHidden" class="row">
      <div class="col-12">
        <div class="card card-border-color card-border-color-success">
          <div class="card-header card-header-divider">
            Add Partner
            <div
              class="btn btn-primary float-right"
              @click="isHidden = !isHidden"
            >
              Cancel
            </div>
          </div>
          <div class="card-body">
            <PlanPartnerCreationForm />
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isHidden" class="row">
      <div class="col-sm-12">
        <div class="card card-table">
          <div class="card-header">
            All Partners
            <div
              class="btn btn-primary float-right"
              @click="isHidden = !isHidden"
              v-if="loggedInUser.role === adminRole"
            >
              Add Partner
            </div>
          </div>
          <div class="card-body">
            <PlanPartnersTable />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import PlanPartnerCreationForm from "@/components/Plans/Partners/CreationForm";
import PlanPartnersTable from "@/components/Plans/Partners/Table";

export default {
  name: "PlanPartnersComponent",
  components: { PlanPartnerCreationForm, PlanPartnersTable },
  data() {
    return {
      isHidden: false,
      adminRole: "ared_admin",
    };
  },
  computed: {
    ...mapGetters(["loggedInUser"]),
  },
};
</script>
<style></style>
