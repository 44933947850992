<template>
  <div>
    <div class="container-fluid pb-3 pt-5 pt-md-6">
      <div class="row col-12 page-head">
        <nav aria-label="breadcrumb" role="navigation">
          <ol class="breadcrumb page-head-nav">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'dashboard' }">Home</router-link>
            </li>
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'plans' }">Plans</router-link>
            </li>
            <li class="breadcrumb-item">Plan Details</li>
          </ol>
        </nav>
      </div>
      <div class="row">
        <div class="user-profile col-md-4 mb-3">
          <div class="user-display">
            <div class="user-display-bg">
              <img src="/img/deal.png" alt="Profile Background" />
            </div>
            <div class="user-display-bottom">
              <div class="user-display-info">
                <div class="name">{{ plan.name | planNameDisplay }}</div>
              </div>
              <div class="row text-center user-display-details">
                <div class="col-10 mx-auto">
                  <div class="title">Partners</div>
                  <div class="counter">{{ partnerCount }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8 mb-3">
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="scroll">
                <h3 class="text-uppercase mb-3">Plan Details</h3>
                <table class="table table-striped">
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <td>{{ plan.name | planNameDisplay }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mb-3">
          <nav class="nav nav-pills nav-fill mb-3">
            <a
              :class="[
                'nav-item',
                'nav-link',
                'mr-1',
                { active: activeTab === 'partners' },
                { 'text-white': activeTab === 'partners' },
              ]"
              v-on:click="setActiveTab('partners')"
              href="#"
            >
              Partners
            </a>
            <a
              :class="[
                'nav-item',
                'nav-link',
                'mr-1',
                { active: activeTab === 'features' },
                { 'text-white': activeTab === 'features' },
              ]"
              v-on:click="setActiveTab('features')"
              href="#"
            >
              Features
            </a>
          </nav>
          <div class="card shadow-sm">
            <div class="card-body">
              <div v-if="activeTab === 'partners'">
                <PlanPartnersComponent />
              </div>
              <div v-if="activeTab === 'features'">
                <PlanFeaturesTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import PlanFeaturesTable from "@/components/Plans/Features/Table";
import PlanPartnersComponent from "@/components/Plans/Partners/PartnerComponent";

export default {
  components: {
    PlanFeaturesTable,
    PlanPartnersComponent,
  },
  filters: {
    planNameDisplay: function (value) {
      if (value == "SME") {
        return "Router Management";
      } else if (value == "Kiosk") {
        return "Kiosk Management";
      } else if (value == "Premium") {
        return "Premium Plan";
      }
    },
  },
  data() {
    return {
      plan: {},
      activeTab: "partners",
      partnerCount: "",
    };
  },
  mounted() {
    this.getPlan(this.$route.params.id);
    this.getPartnersCount(this.$route.params.id);
  },
  methods: {
    getPlan(id) {
      axios
        .get(`v1/plans/${id}/`)
        .then((response) => {
          if (response.status === 200) {
            this.plan = response.data;
            console.log(this.plan);
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$swal("Error", `${error.response.data.detail}`, "error");
          }
        });
    },
    getPartnersCount(id) {
      axios
        .get(`v1/partners/?plan=${id}`)
        .then((response) => {
          if (response.status === 200) {
            this.partnerCount = response.data.length;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$swal("Error", `${error.response.data.detail}`, "error");
          }
        });
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
  },
};
</script>

<style></style>
