<template>
  <table class="table table-striped table-hover table-fw-widget" id="table5">
    <thead>
      <tr>
        <th>Name</th>
        <th>Email</th>
        <th>Phone Number</th>
        <th>Country</th>
        <th>Added</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="partner in partners" :key="partner.id" class="odd gradeX">
        <td>{{ partner.name }}</td>
        <td>{{ partner.email }}</td>
        <td>{{ partner.phone_number }}</td>
        <td>{{ partner.country }}</td>
        <td>
          {{ new Date(partner.created).toLocaleString() }}
        </td>
        <td>
          <router-link
            :to="{ name: 'partner-details', params: { id: partner.id } }"
          >
            <button class="btn btn-primary">View</button>
          </router-link>
        </td>
        <td class="center"></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import axios from "axios";

export default {
  name: "PlanPartnersTable",
  data() {
    return {
      partners: "",
      error: "",
    };
  },
  mounted() {
    this.getPartners(this.$route.params.id);
  },
  methods: {
    getPartners(id) {
      axios
        .get(`v1/partners/?plan=${id}`)
        .then((response) => {
          if (response.status === 200) {
            this.partners = response.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$swal("Error", `${error.response.data.detail}`, "error");
          }
        });
    },
  },
};
</script>
