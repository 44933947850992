var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-fluid pb-3 pt-5 pt-md-6"},[_c('div',{staticClass:"row col-12 page-head"},[_c('nav',{attrs:{"aria-label":"breadcrumb","role":"navigation"}},[_c('ol',{staticClass:"breadcrumb page-head-nav"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'dashboard' }}},[_vm._v("Home")])],1),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'plans' }}},[_vm._v("Plans")])],1),_c('li',{staticClass:"breadcrumb-item"},[_vm._v("Plan Details")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"user-profile col-md-4 mb-3"},[_c('div',{staticClass:"user-display"},[_vm._m(0),_c('div',{staticClass:"user-display-bottom"},[_c('div',{staticClass:"user-display-info"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm._f("planNameDisplay")(_vm.plan.name)))])]),_c('div',{staticClass:"row text-center user-display-details"},[_c('div',{staticClass:"col-10 mx-auto"},[_c('div',{staticClass:"title"},[_vm._v("Partners")]),_c('div',{staticClass:"counter"},[_vm._v(_vm._s(_vm.partnerCount))])])])])])]),_c('div',{staticClass:"col-md-8 mb-3"},[_c('div',{staticClass:"card shadow-sm"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"scroll"},[_c('h3',{staticClass:"text-uppercase mb-3"},[_vm._v("Plan Details")]),_c('table',{staticClass:"table table-striped"},[_c('tbody',[_c('tr',[_c('th',[_vm._v("Name")]),_c('td',[_vm._v(_vm._s(_vm._f("planNameDisplay")(_vm.plan.name)))])])])])])])])]),_c('div',{staticClass:"col-md-12 mb-3"},[_c('nav',{staticClass:"nav nav-pills nav-fill mb-3"},[_c('a',{class:[
              'nav-item',
              'nav-link',
              'mr-1',
              { active: _vm.activeTab === 'partners' },
              { 'text-white': _vm.activeTab === 'partners' },
            ],attrs:{"href":"#"},on:{"click":function($event){return _vm.setActiveTab('partners')}}},[_vm._v(" Partners ")]),_c('a',{class:[
              'nav-item',
              'nav-link',
              'mr-1',
              { active: _vm.activeTab === 'features' },
              { 'text-white': _vm.activeTab === 'features' },
            ],attrs:{"href":"#"},on:{"click":function($event){return _vm.setActiveTab('features')}}},[_vm._v(" Features ")])]),_c('div',{staticClass:"card shadow-sm"},[_c('div',{staticClass:"card-body"},[(_vm.activeTab === 'partners')?_c('div',[_c('PlanPartnersComponent')],1):_vm._e(),(_vm.activeTab === 'features')?_c('div',[_c('PlanFeaturesTable')],1):_vm._e()])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-display-bg"},[_c('img',{attrs:{"src":"/img/deal.png","alt":"Profile Background"}})])
}]

export { render, staticRenderFns }